import { Fragment, useEffect } from "react";
import Agency from "../components/Landing/Agency";
import Cover from "../components/Landing/Cover";
import InfoBar from "../components/Landing/InfoBar";
import Mark from "../components/Landing/Marks/Marks";
import Products from "../components/Landing/Products/Products";
import "./maps.css";


function Landing() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Fragment>
            <Cover />
            <Agency />
            <Products />
            <Mark />
            <InfoBar />
            <iframe className="maps w-full" height="200" id="gmap_canvas" title="maps" src="https://maps.google.com/maps?q=P2QG+XH2,%20Hydra&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
        </Fragment>
    );
}

export default Landing;
