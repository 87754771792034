import React, { Fragment } from 'react';

const Agency = () => {
    return (
        <Fragment>
            <div className='py-10 bg-gray-100'>
                <div className='w-full flex flex-col items-center'>
                    <div className='grid sm:grid-cols-3 grid-cols-1 sm:gap-6 sm:w-9/12 w-11/12 pt-10 text-justify'>
                        <h1 className='sm:text-5xl text-4xl font-semibold'>L'AGENCE</h1>
                        <p className='text-gray-500 font-semibold text-sm col-span-2 bg-gray-50 p-3'>Une riche expérience acquise depuis notre création en 2008. Une agence qui prône la créativité tout en se basant sur une réflexion stratégique ; c'est tout simplement Média Stratégie, une équipe dynamique, ambitieuse et ayant surtout le souci du détail.
                        </p>
                        <div className='sm:mt-0 mt-4'>
                            <h2 className='font-semibold mb-1'>
                                Notre mission
                            </h2>
                            <p className='text-gray-500 text-sm'>
                                Rayonner les communications publicitaires de nos clients, chaque Stratégiste est animé par l’apport d’une vision stratégique, innovante, créative et distinctive.
                            </p>
                        </div>
                        <div className='col-span-2 sm:mt-0 mt-4'>
                            <h2 className='font-semibold mb-1'>
                                Ce qui nous anime
                            </h2>
                            <p className='text-gray-500 text-sm'>
                                Travailler avec un esprit curieux et une forte ambition de grandir dans un environnement épanouissant avec ultime motivation ; réussir ce que nous entreprenons.
                            </p>
                        </div>
                        <div className='sm:mt-0 mt-4'>
                            <h2 className='font-semibold mb-1'>
                                Notre force
                            </h2>
                            <p className='text-gray-500 text-sm'>
                                Construire des stratégies efficaces et apporter multiples expériences pour chacune d’entre elles.
                            </p>
                        </div>
                        <div className='col-span-2 sm:mt-0 mt-4'>
                            <h2 className='font-semibold mb-1'>
                                Ce qui nous différencie
                            </h2>
                            <p className='text-gray-500 text-sm'>
                                Nous croyons fermement au changement, notre devise Be The Change.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Agency