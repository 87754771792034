import { useState, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper";

import "swiper/css";
import './Marks.scss';
import "swiper/css/navigation";
import dataMarks from "./dataMarks";
import './style.css'

function Marks() {
  const [mobile, setMobile] = useState(false);
  const changeSizeNav = () => {
    if(window.innerWidth > 640){
      setMobile(false);
    }else{
      setMobile(true);
    }
  }
  window.addEventListener("resize",changeSizeNav);

  return (
    <Fragment>
      <div className="mx-auto mb-16 sm:px-0 px-8 sm:w-4/5">
        {/* <div className="flex mb-8 mt-16">
          <span className="text-[#4899ba] text-base font-semibold comfortaafont">Ils nous font confiance</span>
          <div className="border-b-2 border-[#4899ba] mb-1.5 mx-2 w-10"></div>
        </div> */}
        <Swiper
          loop={true}
          slidesPerView={mobile ? 3 : 6}
          navigation={true}
          centeredSlides={false}
          modules={[Navigation, Autoplay, Thumbs]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          spaceBetween={0}
          className="mySwiper"
        >
        {dataMarks.map((mark) => {
          return <SwiperSlide key={mark.id}>
            <img className={`mx-auto ${mobile ? "w-16 h-16" : "w-20 h-20" } style`} src={mark.image} alt="" />
          </SwiperSlide>
        })}
        </Swiper>
      </div>
    </Fragment>
  );
}

export default Marks;
