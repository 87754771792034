import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <section class="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
            <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                <div class="max-w-md text-center">
                    <h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
                        <span class="sr-only">Error</span>404
                    </h2>
                    <p class="text-2xl font-semibold md:text-3xl">Désolé, nous n'avons pas trouvé cette page.</p>
                    <p class="mt-4 mb-8 dark:text-gray-400">Mais ne vous inquiétez pas, vous pouvez trouver beaucoup d'autres choses sur notre page d'accueil.</p>
                    <Link
                        to="/"
                        className="flex text-white hover:text-black justify-center text-sm bg-black uppercase mt-10 px-10 py-1 hover:bg-transparent border-2 border-black font-semibold comfortaafont"
                    >
                        <span className="p-3 px-4 text-center">Revenir sur la page d'accueil</span>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default NotFound