import { Fragment } from "react";
import MainNavigation from "./MainNavigation";
import Footer from "./Footer";

function Layout(props) {
  return (
    <Fragment>
      <MainNavigation></MainNavigation>
      <main className="relative overflow-hidden w-full min-h-screen">
        <div>
          {props.children}
        </div>
      </main>
      <Footer />
    </Fragment>
  );
}

export default Layout;
