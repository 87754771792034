var img1 = require('../images/allproducts/Calque 24.png');
var img2 = require('../images/allproducts/Calque 26.png');
var img3 = require('../images/allproducts/Calque 37.png');
var img4 = require('../images/allproducts/Calque 39.png');
var img5 = require('../images/allproducts/Calque 40.png');
var img6 = require('../images/allproducts/Calque 50.png');
// var img7 = require('../images/allproducts/Calque 7.png');
// var img8 = require('../images/allproducts/Calque 8.png');
// var img9 = require('../images/allproducts/Calque 9.png');
var img10 = require('../images/allproducts/Calque 10.png');
// var img11 = require('../images/allproducts/Calque 11.png');
var img12 = require('../images/allproducts/Calque 12.png');
// var img13 = require('../images/allproducts/Calque 13.png');
var img14 = require('../images/allproducts/Calque 14.png');
var img15 = require('../images/allproducts/Calque 15.png');
// var img16 = require('../images/allproducts/Calque 16.png');
// var img17 = require('../images/allproducts/Calque 17.png');
// var img18 = require('../images/allproducts/Calque 18.png');
var img19 = require('../images/allproducts/Calque 19.png');
var img20 = require('../images/allproducts/Calque 20.png');
var img21 = require('../images/allproducts/Calque 21.png');
var img22 = require('../images/allproducts/Calque 22.png');
// var img23 = require('../images/allproducts/Calque 23.png');
var img24 = require('../images/allproducts/Calque 1.png');
var img25 = require('../images/allproducts/Calque 25.png');
var img26 = require('../images/allproducts/Calque 2.png');
var img27 = require('../images/allproducts/Calque 27.png');
var img28 = require('../images/allproducts/Calque 28.png');
// var img29 = require('../images/allproducts/Calque 29.png');
var img30 = require('../images/allproducts/Calque 30.png');
var img31 = require('../images/allproducts/Calque 31.png');
var img32 = require('../images/allproducts/Calque 32.png');
var img33 = require('../images/allproducts/Calque 33.png');
var img34 = require('../images/allproducts/Calque 34.png');
var img35 = require('../images/allproducts/Calque 35.png');
var img36 = require('../images/allproducts/Calque 36.png');
var img37 = require('../images/allproducts/Calque 3.png');
var img38 = require('../images/allproducts/Calque 38.png');
// var img39 = require('../images/allproducts/Calque 4.png');
var img41 = require('../images/allproducts/Calque 41.png');
// var img42 = require('../images/allproducts/Calque 42.png');
var img43 = require('../images/allproducts/Calque 43.png');
var img44 = require('../images/allproducts/Calque 44.png');
// var img45 = require('../images/allproducts/Calque 45.png');
var img46 = require('../images/allproducts/Calque 46.png');
// var img47 = require('../images/allproducts/Calque 47.png');
var img48 = require('../images/allproducts/Calque 48.png');
var img49 = require('../images/allproducts/Calque 49.png');
var img50 = require('../images/allproducts/Calque 6.png');
// var img51 = require('../images/allproducts/Calque 51.png');
// var img52 = require('../images/allproducts/Calque 52.png');
var img53 = require('../images/allproducts/Calque 53.png');
var img54 = require('../images/allproducts/Calque 54.png');
var img55 = require('../images/allproducts/Calque 55.png');
var img56 = require('../images/allproducts/Calque 56.png');
var img57 = require('../images/allproducts/Calque 58.png');

// var img58 = require('../images/allproducts/Calque 59.png');
var img59 = require('../images/allproducts/Calque 60.png');
var img60 = require('../images/allproducts/Calque 61.png');
var img61 = require('../images/allproducts/Calque 62.png');
var img62 = require('../images/allproducts/Calque 63.png');
var img63 = require('../images/allproducts/Calque 64.png');
var img64 = require('../images/allproducts/Calque 65.png');


var DATA = [
    { "id": 5, "image": img6, "title": "Many", video: "-BETy9P-L3o", description: "djazairiat"},
    { "id": 2, "image": img2, "title": "Cotex", video: "3KdhPUSgIDA" , description: "Absorba" },
    { "id": 63, "image": img63, "title": "Caps", video: "K2D0-QTD0M4", description: "toujours" },
    { "id": 6, "image": img5, "title": "Ramy", video: "VqbRt5QXMn8", description: "EXTRA" },
    { "id": 25, "image": img25, "title": "Marinara", video: "0HwR7d7gk6g", description: "hada howa sérieux" },
    { "id": 44, "image": img44, "title": "RicamaR", video: "Kk7GiJFH1TM", description: "wentouma djarebtou" },
    { "id": 60, "image": img60, "title": "Many", video: "QBgXJ1TVFWU", description: "fen etabkh" },
    { "id": 61, "image": img61, "title": "Cotex", video: "sAtdqj0ILnA", description: "Absorba" },
    { "id": 62, "image": img62, "title": "Cotex", video: "k4hoZEwJJNk", description: "Absorba" },
    { "id": 1, "image": img1, "title": "Top Sol", video: "6tVD6RXJW8w",video2: "gAW5ZaSxTMM", video3: "NRqD89sKYvU", video4: "GGjZP5HvT90", video5: "XKLfPZZWqXM", video6:"nCs9_ooy6ao", description: "Top placard" },
    { "id": 59, "image": img59, "title": "Ramy", video: "kEOKhw4_-ik", description: "lemhabba" },
    { "id": 54, "image": img54, "title": "Stream", video: "-HJU36QsdBs", description: "Smart Familly" },
    { "id": 53, "image": img53, "title": "Stream", video: "D2ml_RWTAmk",video2: "L0uapPITmS4", video3:"iwPgtwUu9xU",video4: "ogZ5UGGQ4EQ", video5: "wzBqIwvuTtY", video6: "27K2RTELi8U", description: "Smart Familly" },
    { "id": 4, "image": img4, "title": "Ramy Energy Drink",video:"d-kz4CVp5_A", video2: "VGsmYF69FcI", video3:"x2MhepQNB5A", description: "Eklebha électrique" },
    { "id": 56, "image": img56, "title": "Cotex Plus", video: "Pbc5pmxVZTU", description: "Verssus"  },
    // { "id": 13, "image": img13, "title": "Nowara", video: "gAW5ZaSxTMM" },
    { "id": 30, "image": img30, "title": "Cotex", video: "IaIrDHgRo_I"},
    { "id": 3, "image": img3, "title": "Ramy", video: "uSo84UFrgVw", description: "lemhabba" },
    { "id": 24, "image": img24, "title": "Optilla", video: "qgRZ2tY6GHs",video2:"jgn6AZEN69Y", video3: "L812eKfuVlE", video4: "X_gM1LU7ovw", video5:"TaC0-htoozc", description: "Les noisettes en folie" },
    { "id": 35, "image": img35, "title": "Magigoût", video: "gwvEmY_25ro", description: "ser elbenna" },
    { "id": 34, "image": img34, "title": "El Bey", video: "eyhytROgDGo" },
    // { "id": 16, "image": img16, "title": "Ramy", video: "gAW5ZaSxTMM" },
    { "id": 27, "image": img27, "title": "Cheese House", video: "no64rzQqkMU" },
    { "id": 48, "image": img48, "title": "Many", video: "VzicMKBAzX4", description: "fen etabkh" },
    { "id": 22, "image": img22, "title": "Ramy Lait", video: "KEgBBI-_bdU" },
    { "id": 36, "image": img36, "title": "Many", video: "AsLvNHsMDKo", description: "fen etabkh" },
    { "id": 33, "image": img33, "title": "Cotex Plus", video: "jNzE3PoxuxM", video2: "uP5V1HmcsUQ", video3: "xgxwrNfZE6g", video4: "NW8pBqDn_wM", video5: "Ndca35xTaMI" },
    { "id": 32, "image": img32, "title": "Cotex", video: "U7SXYT_00_U", description: "Papier mouchoir" },
    { "id": 49, "image": img49, "title": "Ramy", video: "PVNbgF30WvY", description: "Water Fruits" },

    // { "id": 42, "image": img42, "title": "Ramy Energy Drink", video: "x2MhepQNB5A", description: "Eklebha électrique" },
    { "id": 28, "image": img28, "title": "Caps", video: "FWVtmp7d_0I", description: "Nouvelles sensations" },
    { "id": 38, "image": img38, "title": "Marinara", video: "kL79b5OTxsU",video2: "B8Aa2LPgYL0", video3: "slcxsKi3Isk",video4: "Clr1OXuWUrw", description: "C’est pas sérieux!" },
    { "id": 64, "image": img64, "title": "Marinara", video: "Fj9xHDtnc1w",video2: "8i8iHczRTsA", video3: "82BYmOC-Gcc",video4: "QWbgXHYk-jU", description: "C’est pas sérieux!" },


    
    // { "id": 29, "image": img29, "title": "Marinara", video: "slcxsKi3Isk", description: "Hawlik e'sérieux!" },
    { "id": 46, "image": img46, "title": "Prospan", video: "lu5VzEGRWFk" },
    { "id": 19, "image": img19, "title": "Ramy", video: "ODHcdzyRF6A" },
    { "id": 41, "image": img41, "title": "Ramy", video: "cqBSzqlRzKw", description: "Écris ton histoire" },
    { "id": 14, "image": img14, "title": "Prospan", video: "4yQjwLWyoxI" },
    { "id": 15, "image": img15, "title": "Ramy Maltée", video: "r2SFh5Ppk4o", description: "Ehki mlih" },
    { "id": 21, "image": img21, "title": "Ramy", video: "16lJ_04Ttnc", description: "Ehki mlih" },
    { "id": 31, "image": img31, "title": "Ramy Lait", video: "vbgZI3AFRyY"},
    { "id": 57, "image": img57, "title": "Polivita", video: "fvTPcLdRklA", video2: "jL7FZJMEq8k", video3: "m0yH6m6p_bI", video4: "hCK8ilZARfg", video5: "OWSmL01UdA4", video6: "EChbHEj2H_U" },
    { "id": 43, "image": img43, "title": "RicamaR", video: "6ydOx6Mwats", video2: "sLbHiWNiraM", description: "Ami rabah" },
    { "id": 37, "image": img37, "title": "Many",video: "oXqeu15oSZg", video2:"h4VgBufnHsY", video3: "93RlFyjB2pQ", description: "l’astuce Dyali" },
    { "id": 55, "image": img55, "title": "Many", video: "qagHnnJ9ZUo", description: "l’astuce Dyali" },
    // { "id": 23, "image": img23, "title": "Ramy Lait", video: "gAW5ZaSxTMM" },
    // { "id": 17, "image": img17, "title": "Ramy", video: "gAW5ZaSxTMM" },
    { "id": 20, "image": img20, "title": "Ramy", video: "cqBSzqlRzKw" },
    // { "id": 8, "image": img8, "title": "Géant", video: "gAW5ZaSxTMM" },
    { "id": 12, "image": img12, "title": "Nadifluo", video: "Avz462H7K0U", description: "Senteur et propreté, tout en un avec Nadifluo" },
    { "id": 26, "image": img26, "title": "Arcodym", video: "Heb4zEASIdQ" },
    // { "id": 39, "image": img39, "title": "Many", video: "gAW5ZaSxTMM", description: "Amina vs Amina" },
    { "id": 50, "image": img50, "title": "Caps", video: "Nb5OPDK0mYI", description: "Nouvelles sensations" }, // hada yroh
    { "id": 10, "image": img10, "title": "Stream", video: "PuUdNURRcAc", description: "Toujours plus, avec Iron 2" },
    // { "id": 11, "image": img11, "title": "Many", video: "gAW5ZaSxTMM" },
    // { "id": 9, "image": img9, "title": "Hodna", video: "gAW5ZaSxTMM", description: "Brassé" },
    // { "id": 18, "image": img18, "title": "Sim", video: "gAW5ZaSxTMM" },






    // { "id": 58, "image": img58, "title": "Ramy", video: "gAW5ZaSxTMM" },

    // { "id": 45, "image": img45, "title": "RicamaR", video: "Kk7GiJFH1TM", description: "RicamaR, rentre chez vous" },
    
    // { "id": 52, "image": img52, "title": "RicamaR", video: "NRqD89sKYvU", description: "RicamaR, rentre chez vous" }, // ma l9itch la vidéo dyalha 
   
    

   
    
    
    
   

   


];


export default DATA;