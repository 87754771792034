import { Link } from "react-router-dom";
import "./Product.css";

function Product(props) {




  return (
    <div
      className={`relative transition ease-in duration-300 transform m-[0.5px] ${
        props.show ? "" : "translate-y-16 opacity-0"
      }`}
    >
      <figure className="product">
        <img className="w-full"
          src={props.product.image}
          alt={props.product.text}
        />
        <figcaption>
          <div >
            <h2 className="text-xl font-bold oswaldfont">{props.product.title}</h2>
            {/* <p className="relative comfortaafont">
              <span className="absolute">Foux de noisettes</span>
              <span className="absolute top-5">{props.product.text}</span>
            </p> */}
          </div>
        </figcaption>
        <Link to={`/realizations/${props.product.id}`} className="absolute inset-0 z-10"></Link>
      </figure>
    </div>
  );
}

export default Product;
