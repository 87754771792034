import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Contact from "./pages/Contact";
import Landing from "./pages/Landing";
import Product from "./pages/Product";
import Realization from "./pages/Realization";
import Team from "./pages/Team";
import NotFound from "./pages/NotFound";


function App() {

  

  return (
    <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="contact" element={<Contact />} />
          <Route path="expertise" element={<Team />} />
          <Route path="realizations" element={<Realization />} />
          <Route path="/realizations/:id" element={<Product />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
  );
}

export default App;
