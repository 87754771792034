import ms5 from "../../../svgs/marks/MS_SITEWEB 3-05.svg";
import ms6 from "../../../svgs/marks/MS_SITEWEB 3-06.svg";
import ms7 from "../../../svgs/marks/MS_SITEWEB 3-07.svg";
import ms8 from "../../../svgs/marks/MS_SITEWEB 3-08.svg"
import ms9 from "../../../svgs/marks/MS_SITEWEB 3-09.svg"
import ms10 from "../../../svgs/marks/MS_SITEWEB 3-10.svg"
import ms11 from "../../../svgs/marks/MS_SITEWEB 3-11.svg"
import ms12 from "../../../svgs/marks/MS_SITEWEB 3-12.svg"
import ms13 from "../../../svgs/marks/MS_SITEWEB 3-13.svg"
import ms14 from "../../../svgs/marks/MS_SITEWEB 3-14.svg"
import ms15 from "../../../svgs/marks/MS_SITEWEB 3-15.svg"
import ms16 from "../../../svgs/marks/MS_SITEWEB 3-16.svg"
import ms17 from "../../../svgs/marks/MS_SITEWEB 3-17.svg"
import ms18 from "../../../svgs/marks/MS_SITEWEB 3-18.svg"
import ms19 from "../../../svgs/marks/MS_SITEWEB 3-19.svg"
import ms20 from "../../../svgs/marks/MS_SITEWEB 3-20.svg"
import ms21 from "../../../svgs/marks/MS_SITEWEB 3-21.svg"
import ms22 from "../../../svgs/marks/MS_SITEWEB 3-22.svg"
import ms23 from "../../../svgs/marks/MS_SITEWEB 3-23.svg"
import ms24 from "../../../svgs/marks/MS_SITEWEB 3-24.svg"
import ms25 from "../../../svgs/marks/MS_SITEWEB 3-25.svg"
import ms26 from "../../../svgs/marks/MS_SITEWEB 3-26.svg"
import ms27 from "../../../svgs/marks/MS_SITEWEB 3-27.svg"
import ms28 from "../../../svgs/marks/MS_SITEWEB 3-28.svg"
import ms29 from "../../../svgs/marks/MS_SITEWEB 3-29.svg"
import ms30 from "../../../svgs/marks/MS_SITEWEB 3-30.svg"
import ms31 from "../../../svgs/marks/MS_SITEWEB 3-31.svg"
import ms32 from "../../../svgs/marks/MS_SITEWEB 3-32.svg"

const dataMarks = [
    { id: 1, text: "ms5", image: ms5 },
    { id: 2, text: "ms6", image: ms6 },
    { id: 3, text: "ms7", image: ms7},
    { id: 4, text: "ms8", image: ms8 },
    { id: 5, text: "ms9", image: ms9 },
    { id: 6, text: "ms10", image: ms10 },
    { id: 7, text: "ms11", image: ms11 },
    { id: 8, text: "ms12", image: ms12 },
    { id: 9, text: "ms13", image: ms13 },
    { id: 10, text: "ms14", image: ms14 },
    { id: 11, text: "ms15", image: ms15 },
    { id: 12, text: "ms16", image: ms16},
    { id: 13, text: "ms17", image: ms17 },
    { id: 14, text: "ms18", image: ms18 },
    { id: 15, text: "ms19", image: ms19 },
    { id: 16, text: "ms20", image: ms20 },
    { id: 17, text: "ms21", image: ms21 },
    { id: 18, text: "ms22", image: ms22 },
    { id: 19, text: "ms23", image: ms23 },
    { id: 20, text: "ms24", image: ms24 },
    { id: 21, text: "ms25", image: ms25},
    { id: 22, text: "ms26", image: ms26 },
    { id: 23, text: "ms27", image: ms27 },
    { id: 24, text: "ms28", image: ms28 },
    { id: 25, text: "ms29", image: ms29 },
    { id: 26, text: "ms30", image: ms30 },
    { id: 27, text: "ms31", image: ms31 },
    { id: 28, text: "ms32", image: ms32 },
  ];
  
  export default dataMarks;
  