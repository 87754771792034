import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import logoms from "../../svgs/LOGO-MS.svg"
import logomsb from "../../svgs/LOGO-MS-B.svg"


function MainNavigation() {
   const location = useLocation();
  const [drop, setDrop] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const changeSizeNav = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }
  window.addEventListener('scroll', changeSizeNav);

  return (
    <header className={`fixed flex w-full sm:p-0 p-4 z-50 ${location.pathname.toString() === "/realizations" ? "bg-white" : navbar ? "bg-white opacity-80 shadow-md transform ease-in-out duration-500" : "bg-transparent transform ease-in-out duration-500"}  oswaldfont`}>
      <nav className={`sm:flex sm:justify-between sm:items-center sm:w-11/12 w-full mx-auto text-white sm:h-[80px] ease-in duration-300 ${navbar ? "" : "sm:h-[100px]"}`}>
        <Link to="/" className="flex items-end">
          <img src={location.pathname.toString() === "/realizations" ? logomsb : navbar ? logomsb : logoms} alt="média stratégie logo" className="h-7 w-auto" />
        </Link>
        <ul className="sm:flex hidden sm:items-center uppercase text-xs font-medium">
          <li>
            <NavLink
              to="/"
              className={(navData) => `${navData.isActive ? "border-b-2" : ""}  p-1 my-1 mx-2  ${location.pathname.toString() === "/realizations" ? "text-black" : navbar ? "text-black border-black" : "text-white border-white"}`}
            >
              accueil
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/realizations"
              className={(navData) => `${navData.isActive ? "border-b-2" : ""}  p-1 my-1 mx-2  ${location.pathname.toString() === "/realizations" ? "text-black border-black" : navbar ? "text-black border-black" : "text-white border-white"}`}
            >
              réalisations
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/expertise"
              className={(navData) => `${navData.isActive ? "border-b-2" : ""}  p-1 my-1 mx-2 ${location.pathname.toString() === "/realizations" ? "text-black" : navbar ? "text-black border-black" : "text-white border-white"}`}
            >
              expertises
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={(navData) => `${navData.isActive ? "border-b-2" : ""}  p-1 my-1 mx-2  ${location.pathname.toString() === "/realizations" ? "text-black" : navbar ? "text-black border-black" : "text-white border-white"}`}
            >
              contact
            </NavLink>
          </li>
        </ul>
      </nav>

      <button onClick={() => setDrop(true)} className="sm:hidden block">
        {
          !drop && <svg className={`h-6 w-6 ${location.pathname.toString() === "/realizations" ? "text-black" : "text-white"}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        }
      </button>

      {drop &&
        <div className="absolute top-0 inset-x-0 transition transform origin-top-right text-white md:hidden bg-black">
          <nav className="overflow-hidden bg-transparent">
            <div className="px-4 pt-2 flex items-center justify-between">
              <Link onClick={() => setDrop(false)} to="/" className="flex items-end">
                <img src={logoms} alt="média stratégie logo" className="h-6" />
              </Link>
              <div className="-mr-2">
                <button onClick={() => setDrop(false)} className="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1 uppercase text-sm font-medium">
              <NavLink onClick={() => setDrop(false)} to="/" className={(navData) => `${navData.isActive ? "border-b-2" : "text-white"} block px-3 py-2 hover:bg-gray-500 text-center`}>accueil</NavLink>
              <NavLink onClick={() => setDrop(false)} to="/realizations" className={(navData) => `${navData.isActive ? "border-b-2" : "text-white"} block px-3 py-2 hover:bg-gray-500 text-center`}>réalisations</NavLink>
              <NavLink onClick={() => setDrop(false)} to="/expertise" className={(navData) => `${navData.isActive ? "border-b-2" : "text-white"} block px-3 py-2 hover:bg-gray-500 text-center`}>expertises</NavLink>
              <NavLink onClick={() => setDrop(false)} to="/contact" className={(navData) => `${navData.isActive ? "border-b-2" : "text-white"} block px-3 py-2 hover:bg-gray-500 text-center`}>contact</NavLink>
            </div>
          </nav>
        </div>
      }
    </header>
  );
}

export default MainNavigation;
