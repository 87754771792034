import React, { Fragment } from 'react';
import gridtile from "../images/gridtile.png"


const Cover = ({ lien, title, description }) => {
    return (
        <Fragment>
            <div
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0,0.5), rgba(0, 0, 0,0.5)),url('${lien}')`,
                }}
                className="relative overflow-hidden w-full h-96 bg-no-repeat bg-cover bg-center"
            >
                <div className="flex flex-col h-96 justify-center items-center text-white w-full"
                    style={{
                        backgroundImage: `url(${gridtile})`,
                    }}
                >
                    <div className="flex flex-col text-center w-full justify-center items-center">
                        <h1 className="sm:text-4xl text-3xl font-bold oswaldfont uppercase p-2 drop-shadow-lg shadow-black w-2/3 ">
                            {title}
                        </h1>
                        <h2 className='sm:text-xl text-lg font-bold oswaldfont uppercase p-2 drop-shadow-lg shadow-black w-2/3'>{description}</h2>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Cover;