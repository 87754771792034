import React, { Fragment, useEffect } from 'react'
import Cover from '../components/Cover'
import Form from '../components/Contact/Form'
import InfoBar from '../components/Landing/InfoBar';
import cover_contact from "../images/contact-cover.png"
import "./maps.css";


function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      {/* <Cover lien={"https://img.freepik.com/premium-photo/contact-us-hand-man-holding-mobile-smartphone-with-mail-phone-email-chat-icon-cutomer-support-concept-blue-wide-banner_256259-2764.jpg?w=2000"} title="Comment pouvons-nous vous aider ?" /> */}
      <Cover lien={cover_contact} title="Comment pouvons-nous vous aider ?" />
      <Form />
      <InfoBar />
      <iframe className="maps w-full" height="200" id="gmap_canvas" title="maps" src="https://maps.google.com/maps?q=P2QG+XH2,%20Hydra&t=&z=15&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
    </Fragment>
  )
}

export default Contact