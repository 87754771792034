import React, { Fragment } from 'react';
import icon1 from "../../svgs/info/MS_SITEWEB1.svg";
import icon2 from "../../svgs/info/MS_SITEWEB2.svg";
import icon3 from "../../svgs/info/MS_SITEWEB3.svg";
import gridtile from "../../images/gridtile.png";

import './barinfo.css'


import video from "../../videos/infobar.mp4";

const InfoBar = () => {
    return (
        <Fragment>
            <div className="relative">
                <div className='w-full h-96 flex justify-center bar'>
                    <div className='w-full flex justify-center absolute h-96' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0,0.5), rgba(0, 0, 0,0.5)), url(${gridtile})` }}>
                        <div className='sm:w-4/5 flex sm:flex-row flex-col sm:justify-between justify-evenly items-center h-96'>
                            <div className='flex flex-col items-center sm:w-1/3'>
                                <img src={icon2} className="h-12 w-12 mb-6" alt="phone" />
                                <span className='text-white text-center'>+ 213 (0) 555 074 306</span>
                            </div>
                            <div className='flex flex-col items-center sm:w-1/3'>
                                <img src={icon1} className="h-12 w-12 mb-6" alt="address" />
                                <span className='text-white text-center'>03 Chemin Kaddous, Hydra, Alger</span>
                            </div>
                            <div className='flex flex-col items-center sm:w-1/3'>
                                <img src={icon3} className="h-12 w-12 mb-6" alt="contact" />
                                <span className='text-white text-center'>contact@mediastrategie-dz.com</span>
                            </div>
                        </div>
                    </div>
                    <video src={video} autoPlay loop muted className='h-screen w-full object-cover' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0,0.7), rgba(0, 0, 0,0.7)), url(${gridtile})` }} />
                </div>
            </div>
        </Fragment>
    )
}

export default InfoBar