import { Fragment, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Masonry from "react-masonry-css";
import Product from "./Product";

import advertisement from "../../../data/advertisement";
import { Link } from "react-router-dom";


const firstadv = advertisement.slice(0, 6);

function Products() {

  const [imagesShownArray, setImagesShownArray] = useState(
    Array(firstadv.length).fill(false)
  );

  const imageVisibleChange = (id, isVisible) => {
    if (isVisible) {
      setImagesShownArray((currentImagesShownArray) => {
        currentImagesShownArray[id] = true;
        return [...currentImagesShownArray];
      });
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <Fragment>
      <div className="sm:mx-auto mx-4">
        
        <div className="flex justify-center">
          <Link
            to="/expertise"
            className="text-sm bg-black sm:mt-10 mt-6 font-semibold text-white uppercase p-3 px-14 border-2 border-black hover:bg-transparent hover:text-black"
          >
            Nos expertises
          </Link>
        </div>

        <div className="py-10 flex flex-col items-center">
          <h1 className='sm:text-3xl text-2xl font-semibold text-center'>NOS DERNIÈRES CAMPAGNES</h1>
          {/* <p className='text-center w-2/3 uppercase pt-5 text-gray-500 font-semibold'>nous sommes fiers de présenter nos dernières campagnes publicitaires, qui ont été conçues et exécutées avec excellence pour répondre aux besoins spécifiques de chaque client.</p> */}
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex"
        // columnClassName="h-96"
        >
          {firstadv.map((product) => (
            <VisibilitySensor
              key={product.id}
              partialVisibility={true}
              offset={{ bottom: 0 }}
              onChange={(isVisible) =>
                imageVisibleChange(product.id, isVisible)
              }
            >
              <Product
                show={imagesShownArray[product.id]}
                product={product}
              />
            </VisibilitySensor>
          ))}
        </Masonry>
      </div>
      <div className="flex justify-center">
        <Link
          to="/realizations"
          className="text-sm bg-black mb-10 sm:mt-10 mt-6 font-semibold text-white uppercase p-3 px-14 border-2 border-black hover:bg-transparent hover:text-black"
        >
          Toutes nos réalisations
        </Link>
      </div>
    </Fragment>
  );
}

export default Products;
