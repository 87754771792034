import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Cover from '../components/Cover';
import products from "../data/advertisement";


function Product() {
  const { id } = useParams();
  const loadedAdv = products.filter((adv) => adv.id.toString() === id.toString());
  const adv = loadedAdv[0];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Cover title={adv.title} description={adv.description} lien={adv.image} />
      <div className='flex justify-center p-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>

      {adv.video2 && <div className='flex justify-center pb-20 px-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video2}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>}

      {adv.video3 && <div className='flex justify-center pb-20 px-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video3}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>}

      {adv.video4 && <div className='flex justify-center pb-20 px-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video4}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>}

      {adv.video5 && <div className='flex justify-center pb-20 px-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video5}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>}

      {adv.video6 && <div className='flex justify-center pb-20 px-20'>
        <iframe width="840" height="454" src={`https://www.youtube.com/embed/${adv.video6}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
      </div>}
    </Fragment>
  )
}

export default Product