import React, { Fragment, useEffect } from 'react'
import Cover from '../components/Cover';

import strategie from "../images/expertises/STRATEGIE.png";
import media from "../images/expertises/MEDIA.png";
import studio from "../images/expertises/studio-graphic.png";
import digital from "../images/expertises/DIGITAL.png";
import media2 from "../images/expertises/MEDIA2.png";

function Team() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Fragment>
      <Cover lien={"https://www.jardines.com/sites/jardine-matheson-corp/files/jardine/investors/AGM-banner-2022.jpg"} title="Nos expertises" />
      <div className='w-full flex flex-col items-center pt-10 pb-20'>
        <div className='grid sm:grid-cols-2 grid-cols-1 sm:gap-6 sm:w-9/12 w-11/12 pt-10 text-justify'>
          <div className='sm:mt-0 mt-4 sm:block hidden'>
            <img className='w-full h-full' src={strategie} alt="stratégie" />
          </div>
          <div className='sm:mt-0 mt-4 flex flex-col justify-center'>
            <h2 className='font-semibold mb-1'>
              Conseil et Stratégie
            </h2>
            <div className='sm:mt-0 my-2 sm:hidden block'>
              <img className='w-full' src={strategie} alt="stratégie" />
            </div>
            <p className='text-gray-500 text-sm'>
              Développer des stratégies de positionnement et des plans de campagnes visant à travailler durablement l'image des marques.
            </p>
          </div>
          <div className='sm:mt-0 mt-4 flex flex-col justify-center'>
            <h2 className='font-semibold mb-1'>
              Publicité
            </h2>
            <div className='sm:mt-0 my-2 sm:hidden block'>
              <img className='w-full' src={media} alt="media" />
            </div>
            <p className='text-gray-500 text-sm'>
              Des campagnes publicitaires qui font changer de regard, bouger les lignes, évoluer les perceptions. Un accompagnement qui apporte un plus juste et utile.
            </p>
          </div>
          <div className='sm:mt-0 mt-4 sm:block hidden'>
            <img className='w-full' src={media} alt="media" />
          </div>
          <div className='sm:mt-0 mt-4 sm:block hidden'>
            <img className='w-full' src={studio} alt="studio" />
          </div>
          <div className='sm:mt-0 mt-4 flex flex-col justify-center'>
            <h2 className='font-semibold mb-1'>
              Création graphique et de contenu
            </h2>
            <div className='sm:mt-0 my-2 sm:hidden block'>
              <img className='w-full' src={studio} alt="studio" />
            </div>
            <p className='text-gray-500 text-sm'>
              Notre équipe de concepteurs, rédacteurs, et créatifs ; jouit d'une expérience avérée, elle est à même de développer soigneusement des visuels graphiques en totale adéquation avec votre image de marque et positionnement.
            </p>
          </div>
          <div className='sm:mt-0 mt-4 flex flex-col justify-center'>
            <h2 className='font-semibold mb-1'>
              Le digital
            </h2>
            <div className='sm:mt-0 my-2 sm:hidden block'>
              <img className='w-full' src={digital} alt="digital" />
            </div>
            <p className='text-gray-500 text-sm'>
              Nous gérons des communautés, nous produisons de contenu, l'influence et nous maîtrisons les plateformes et outils digitaux. Nous créons du contenu viral à tendances et best practices du web.
            </p>
          </div>
          <div className='sm:mt-0 mt-4 sm:block hidden'>
            <img className='w-full' src={digital} alt="digital" />
          </div>
          <div className='sm:mt-0 mt-4 sm:block hidden'>
            <img className='w-full' src={media2} alt="media2" />
          </div>
          <div className='sm:mt-0 mt-4 flex flex-col justify-center'>
            <h2 className='font-semibold mb-1'>
              Le média
            </h2>
            <div className='sm:mt-0 my-2 sm:hidden block'>
              <img className='w-full' src={media2} alt="media2" />
            </div>
            <p className='text-gray-500 text-sm'>
              Un accompagnement, un conseil prodigieux et pragmatique sur tous types de support de communication ; affichage fixe ou roulant, télévision, radio, presse écrite et cinéma. Notre objectif garantir une bonne visibilité à vos campagnes.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Team