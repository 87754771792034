// import { Link } from "react-router-dom";
// import logomsb from "../../svgs/LOGO-MS-B.svg"
// import world from "../../svgs/world.svg";
// import home from "../../svgs/home.svg";
// import phone from "../../svgs/phone-call.svg";
// import envelope from "../../svgs/envelope.svg";
import facebook from "../../svgs/facebook.svg";
import instagram from "../../svgs/instagram.svg";
import youtube from "../../svgs/youtube.svg";
import linkedin from "../../svgs/linkedin.svg";

function Footer() {
  return (
    <footer className="w-full bottom-0 comfortaafont">
      {/* <div className="bg-white text-black">
        <div className="flex sm:flex-row flex-col justify-between mx-auto sm:py-4 sm:px-0 px-8 py-4 text-xs  text-center sm:w-11/12">
          <div className="flex flex-col items-start">
          
            <div className="flex items-center text-left mb-1">
              <span>Adresse: 03 Chemin kaddous, Hydra Alger</span>
            </div>
            <div className="flex items-end text-left mb-1">
              <span>Tél: 213 (0) 555 000 555</span>
            </div>
            <div className="flex items-end text-left mb-1">
              <span>Email: contact@mediastrategie-dz.com</span>
            </div>
            <div className="flex items-end text-left mb-1">
              <span>Site web: www.mediastrategie-dz.com</span>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <Link to="/" className="mb-1">Accueil</Link>
            <Link to="/realizations" className="mb-1">Nos réalisations</Link>
            <Link to="/expertise" className="mb-1">Expertise</Link>
            <Link to="/contact" className="mb-1">Contact</Link>
          </div>
          <div className="flex flex-col items-end justify-between">
            <div className="flex flex-col items-start text-lg librebaskervillefont">
              <img src={logomsb} alt="média stratégie logo" className="h-5 sm:mt-0 mt-4" />
            </div>
            <div>
              <p className="py-2">Suivez-nous sur</p>
              <div className="flex w-full sm:justify-end">
                <a href="https://www.facebook.com"><img src={facebook} alt="facebook" className="w-4 h-4 mr-2" /></a>
                <a href="https://www.instagram.com"><img src={instagram} alt="instagram" className="w-4 h-4 mr-2" /></a>
                <a href="https://www.linkedin.com"><img src={linkedin} alt="linkedin" className="w-4 h-4 mr-2" /></a>
                <a href="https://www.youtube.com"><img src={youtube} alt="youtube" className="w-4 h-4" /></a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="sm:px-0 px-8">
        <hr className="border-[#4899ba] sm:w-11/12 mx-auto" />
      </div> */}
      <div className="bg-black">
        <div className="flex justify-between sm:flex-row flex-col mx-auto py-4 sm:px-0 px-8 text-xs text-white text-center sm:w-11/12">
          <span>Agence Média Stratégie. Copyright © 2023 - Tous droit réservés</span>
          <div className="flex justify-center ">
            <p className="pr-2">Suivez-nous sur</p>
            <a href="https://www.facebook.com/Media.Strategie.Officiel"><img src={facebook} alt="facebook" className="w-4 h-4 mr-2" /></a>
            <a href="https://www.instagram.com/media_strategie/"><img src={instagram} alt="instagram" className="w-4 h-4 mr-2" /></a>
            <a href="https://www.linkedin.com/company/m%C3%A9dia-strat%C3%A9gie"><img src={linkedin} alt="linkedin" className="w-4 h-4 mr-2" /></a>
            <a href="https://www.youtube.com/@MediaStrategieAlgerie"><img src={youtube} alt="youtube" className="w-4 h-4" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
