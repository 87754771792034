import { Fragment, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Masonry from "react-masonry-css";
import Product from "../Landing/Products/Product";
import allproducts from "../../data/advertisement"

function AllProducts() {

  const [imagesShownArray, setImagesShownArray] = useState(
    Array(allproducts.length).fill(false)
  );

  const imageVisibleChange = (id, isVisible) => {
    if (isVisible) {
      setImagesShownArray((currentImagesShownArray) => {
        currentImagesShownArray[id] = true;
        return [...currentImagesShownArray];
      });
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <Fragment>
      <div className="sm:mx-auto mx-4 mt-[80px]">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="flex"
        >
          {allproducts.map((product) => (
            <VisibilitySensor
              key={product.id}
              partialVisibility={true}
              offset={{ bottom: 0 }}
              onChange={(isVisible) =>
                imageVisibleChange(product.id, isVisible)
              }
            >
              <Product
                show={imagesShownArray[product.id]}
                product={product}
              />
            </VisibilitySensor>
          ))}
        </Masonry>
      </div>
      
    </Fragment>
  );
}

export default AllProducts;
