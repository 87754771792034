import React, { Fragment, useRef } from 'react'
import { Link } from "react-router-dom";
import gridtile from "../../images/gridtile.png";
import anglebas from "../../svgs/angle-vers-le-bas.svg";
import video from "../../videos/Showreel_MS_FORWEBSITE.mp4";


const Cover = () => {
    const textsRef = useRef(null);
    const executeScroll = () =>
        window.scrollTo({ behavior: "smooth", top: textsRef.current.offsetTop });
    return (
        <Fragment>
            <div className='relative'>
                <div className="absolute flex flex-col h-screen justify-center items-center text-white w-full"
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0,0.7), rgba(0, 0, 0,0.7)), url(${gridtile})`,
                    }}
                >
                    <button
                        onClick={executeScroll}
                        className="flex flex-col items-center absolute text-white bottom-0 hover:cursor-pointer z-20"
                    >
                        <img src={anglebas} className="h-14 opacity-60" alt="arrow" />
                    </button>
                    <div className="flex flex-col text-center w-full justify-center items-center">
                        <h1 ref={textsRef} className="sm:text-4xl text-xl font-bold oswaldfont uppercase p-2 drop-shadow-lg shadow-black sm:w-2/3 w-10/12 tracking-widest">
                            Agence de Stratégie et Conseils en Communication
                        </h1>
                        <Link
                            to="realizations"
                            className="flex items-center text-sm bg-black uppercase mx-auto mt-10 px-10 py-1 hover:bg-transparent border-2 border-black font-semibold comfortaafont z-40"
                        >
                            <span className="p-3 px-4 hover:text-black">découvrez nos réalisations</span>
                        </Link>
                    </div>
                </div>
                <video src={video} autoPlay loop muted className='h-screen w-full object-cover' />
            </div>
        </Fragment>
    )
}

export default Cover;