import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cqhgeqf', 'template_0l8o3kg', form.current, 'diUuO9M7VuPcUTFw9')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset();
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <section className="text-gray-700 body-font relative">
                <div className="container px-5 py-12 mx-auto">
                    <div className="lg:w-1/2 md:w-2/3 mx-auto">
                        <div className="flex flex-wrap -m-2">
                            <div className="p-2 sm:w-1/2 w-full">
                                <div className="relative">
                                    {/* <label for="name" className="leading-7 text-sm text-gray-600">
                                        Nom
                                    </label> */}
                                    <input
                                        type="text"
                                        id="lastname"
                                        name="lastname"
                                        placeholder='Nom'
                                        className="w-full bg-gray-100 border-b border-gray-300 focus:border-black text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="p-2 sm:w-1/2 w-full">
                                <div className="relative">
                                    {/* <label for="name" className="leading-7 text-sm text-gray-600">
                                        Prénom
                                    </label> */}
                                    <input
                                        type="text"
                                        id="firstname"
                                        name="firstname"
                                        placeholder='Prénom'
                                        className="w-full bg-gray-100 border-b border-gray-300 focus:border-black text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder='Email'
                                        className="w-full bg-gray-100 border-b border-gray-300 focus:border-black text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative">
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        placeholder='Numéro de Téléphone'
                                        className="w-full bg-gray-100 border-b border-gray-300 focus:border-black text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                    />
                                </div>
                            </div>
                            <div className="p-2 w-full">
                                <div className="relative">
                                    <textarea
                                        id="message"
                                        name="message"
                                        placeholder='Message'
                                        className="w-full bg-gray-100 border-b border-gray-300 focus:border-black h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="p-2 flex justify-center w-full">
                                <button
                                    className="text-sm bg-black mt-5 font-semibold text-white uppercase p-3 px-14 border-2 border-black hover:bg-transparent hover:text-black"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default Form